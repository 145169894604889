<template>
  <v-container fluid>
    <common-error
      v-if="isError"
      @fetchData="fetchContractDetail"
    />
    <skelaton-loader
      v-else-if="isLoading"
    />
    <v-row v-else>
      <v-col>
        <p class="ml-2" v-if="!(contractBy === 'adendum-kontrak')"><strong> {{ title }}</strong></p>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          active-class="font-weight-bold"
        >
          <v-tab class="mr-4 text-capitalize body-1">
            <span>{{contractBy === 'adendum-kontrak' ? title : $_strings.contract.PAGE_NAME_SHIPPER}}</span>
          </v-tab>
          <v-tab class="text-capitalize body-1" v-if="contractBy !== 'adendum-kontrak'">
            <span>{{ $_strings.contract.label.ATTACHMENT }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="transparent"
        >
          <v-tab-item>
            <v-card
              outlined
              class="transparent"
            >
              <section class="white pa-5 elevation-1">
                <FormInput
                  :form="form"
                  :itemsCompanyList="itemsCompanyList"
                  :cityList="cityList"
                  @updateCompanyList="updateCompanyList"
                  @updateCityList="updateCityList"
                  ref="form"
                />
              </section>
              <section class="pa-3">
                <TableUpload
                  :readonly="readonly"
                  :form="form"
                  :documents="form.documents"
                  :newUploadDocuments="newUploadDocuments"
                  documentType="CONTRACT"
                  :label="contractBy === 'adendum-kontrak' ? 'Unggah Addendum' : 'Unggah Kontrak'"
                />
              </section>
              <section class="d-flex justify-end">
                <v-btn
                  v-if="contractBy === 'adendum-kontrak' && !readonly"
                  color="primary"
                  class="mt-4"
                  large
                  @click="submit"
                >
                  {{$_strings.contract.label.SAVE}}
                </v-btn>
                <div v-else-if="contractBy === 'adendum-kontrak' && readonly"></div>
                <v-btn
                  v-else
                  class="mt-4"
                  color="primary"
                  large
                  @click="tab = 1"
                >
                  {{ $_strings.contract.label.NEXT }}
                </v-btn>
              </section>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              outlined
              class="transparent"
            >
              <v-container fluid class="white pa-5 elevation-1">
                <TableUpload
                  :readonly="readonly"
                  class="pl-2 pr-2 pb-5 pt-0"
                  :form="form"
                  :documents="form.documents"
                  :newUploadDocuments="newUploadDocuments"
                  documentType="ATTACHMENT"
                  label="Unggah Lampiran"
                />
                <!-- BUTTONS ACTIONS -->
                <v-row>
                  <v-col>
                    <v-btn
                      depressed
                      outlined
                      large
                      @click="tab = 0"
                    >
                      {{ $_strings.contract.label.BACK }}
                    </v-btn>
                  </v-col>
                  <v-col class="d-flex justify-end">
                    <v-btn
                      color="primary"
                      class="ml-4"
                      large
                      @click="submit"
                      v-if="!readonly"
                    >
                      {{$_strings.contract.label.SAVE}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FormInput from './FormInput.vue';
import TableUpload from '../TableUpload.vue';
import SkelatonLoader from '../SkeletonLoader.vue';

export default {
  components: {
    FormInput,
    TableUpload,
    SkelatonLoader,
  },
  mounted() {
    if (this.action === 'view') {
      this.fetchContractDetail();
    }
  },
  beforeDestroy() {
    if (this.newUploadDocuments.length && !this.isSubmited) {
      const dataUrl = [];
      this.newUploadDocuments.forEach((doc) => {
        dataUrl.push({
          url: doc.url,
        });
      });
      Promise.all(dataUrl.map((payload) => this.$_services.contract.deleteFile(payload)));
    }
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      tab: 0,
      form: {
        contractNo: '',
        contractFormatType: null,
        companyId: '',
        mouId: null,
        mouNo: '',
        periodStart: null,
        periodEnd: null,
        documents: [],
      },
      itemsCompanyList: [],
      cityList: [],
      radioGroupCompany: null,
      newUploadDocuments: [],
      isSubmited: false,
    };
  },
  computed: {
    contractBy() {
      const [, , path] = this.$route.path.split('/');
      return path;
    },
    title() {
      if (this.contractBy === 'kontrak-shipper') {
        return this.$_strings.contract.label.ADD_CONTRACT_SHIPPER;
      }
      if (this.contractBy === 'kontrak-3pl') {
        return this.$_strings.contract.label.ADD_CONTRACT_3PL;
      }
      return this.$_strings.addendumContract.label.ADENDUM;
    },
    action() {
      // RETURN => CREATE | VIEW
      const [, , , path] = this.$route.path.split('/');
      return path;
    },
    readonly() {
      return this.action === 'view';
    },
  },
  methods: {
    updateCompanyList(items) {
      this.itemsCompanyList = [...this.itemsCompanyList, ...items];
    },
    updateCityList(items) {
      this.cityList = [...this.cityList, ...items];
    },
    async fetchContractDetail() {
      const { contractId } = this.$route.params;
      try {
        this.isLoading = true;
        this.isError = false;
        const res = await this.$_services.contract.fetchContractDetailById(contractId);
        this.form = {
          ...res.data,
          effectiveDate: res.data.effectiveDatetime,
          locationName: res.data.addendumLocation,
          addendumDate: res.data.addendumDatetime,
        };

        // SET COMPANY LIST FOR SHOW COMPANY NAME
        this.itemsCompanyList.push({
          companyName: res.data.companyName,
          companyId: res.data.companyId,
        });

        // SET CITY LIST FOR SHOW CITY NAME
        this.cityList.push({
          name: res.data.addendumLocation,
        });
      } catch (e) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    service() {
      switch (this.action) {
        case 'create': {
          if (this.contractBy === 'adendum-kontrak') {
            return this.$_services.contract.submitAddendum;
          }
          return this.$_services.contract.submitContract;
        }
        default: return null;
      }
    },
    validate() {
      if (!this.$refs.form.$refs.form.validate()) {
        this.tab = 0;
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        return false;
      }
      // DOKUMEN
      if (this.form.documents.length === 0) {
        this.$dialog.notify.error('Minimal dokumen kontrak harus di upload');
        return false;
      }
      return true;
    },
    async submit() {
      const { contractId } = this.$route.params;
      if (this.validate()) {
        try {
          this.$root.$loading.show();
          await this.service()(this.form, contractId);
          this.isSubmited = true;
          this.$dialog.notify.success('Berhasil');
          this.$router.push(`/main/${this.contractBy}`);
        } finally {
          this.$root.$loading.hide();
        }
      }
    },
  },
};
</script>
