<template>
  <section>
    <v-row class="mt-1">
      <v-col cols="6">
        <v-skeleton-loader
          type="heading"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" v-for="x in tabRepeat" :key="x">
        <v-skeleton-loader
          type="text"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-card>
      <v-row v-for="skeleton in 5" :key="skeleton">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="9">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mt-4">
      <v-col cols="6">
        <v-skeleton-loader
          type="heading"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col>
        <v-skeleton-loader
          type="image"
          height="80"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  created() {
    this.interval = setInterval(() => {
      if (this.tabRepeat > 2) {
        this.tabRepeat = 1;
        return;
      }
      this.tabRepeat += 1;
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  data() {
    return {
      tabRepeat: 1,
      interval: null,
    };
  },
};
</script>
