<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <!-- ADDENDUM -->
    <v-row v-if="contractBy === 'adendum-kontrak'" class="d-flex">
      <v-col cols="12" sm="4" md="3" class="d-flex align-center">
        <p>{{ $_strings.addendumContract.label.PARTNER_TYPE}}<span class="red--text">*</span></p>
      </v-col>
      <v-col cols="12" sm="auto">
        <v-radio-group
          v-model="form.companyType"
          @change="fetchCompanyList('')"
          :rules="rules.partnerRule"
          hide-details
          :disabled="readonly"
        >
          <v-radio :key="1" value="SHIPPER" label="Shipper"></v-radio>
          <v-radio :key="2" value="TRANSPORTER" label="Rekanan 3PL"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <!-- ============ -->

    <v-row v-else>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{ $_strings.contract.label.CONTRACT_FORMAT}} <span class="red--text">*</span></span>
      </v-col>
      <v-col class="pt-0" cols="12" sm="auto">
        <v-radio-group
          class="ma-0"
          v-model="form.contractFormatType"
          :rules="rules.contractFormatType"
          :disabled="readonly"
        >
          <v-radio
            :value="'FASTLOG'"
            label="Kontrak Format Fastlog"
          ></v-radio>
          <v-radio
            :value="'COMPANY'"
            :label="contractFormatType"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{ $_strings.contract.header.COMPANY_NAME}} <span class="red--text">*</span></span>
      </v-col>
      <v-col md="9">
        <v-tooltip v-model="showTooltipCompanyName" left>
          <template v-slot:activator="{ }">
            <v-autocomplete
              dense
              outlined
              item-text="companyName"
              item-value="companyId"
              placeholder="Nama Perusahaan"
              :rules="rules.companyNameRules"
              :items="itemsCompanyList"
              v-model="form.companyId"
              @change="($event) => setForm($event)"
              :loading="isLoadingCompanyList"
              @input.native="($event) => fetchCompanyList($event.target.value)"
              :clearable="!readonly"
              :disabled="readonly"
              @focus="(!form.companyType && contractBy === 'adendum-kontrak') ? showTooltipCompanyName = true : showTooltipCompanyName = false"
              @blur="showTooltipCompanyName = false"
            >
            </v-autocomplete>
          </template>
          <p>Jenis Mitra Belum Dipilih</p>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{ $_strings.contract.label.MOU_NUMBER }}<span class="red--text">*</span></span>
      </v-col>
      <v-col md="9">
        <v-text-field
          :value="form.mouNo"
          :rules="rules.mouRule"
          dense
          outlined
          disabled
          placeholder="Nomor MoU"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{ $_strings.contract.header.CONTRACT_NUMBER}} <span class="red--text">*</span></span>
      </v-col>
      <v-col md="9">
        <v-text-field
          height="30"
          outlined
          dense
          placeholder="Nomor Kontrak"
          v-model="form.contractNo"
          disabled
          aria-hidden="true"
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{ $_strings.contract.label.EFFECTIVE_PERIOD}} <span class="red--text">*</span></span>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          height="30"
          outlined
          dense
          placeholder="Tanggal"
          disabled
          :value="form.periodStart ? dayjs(form.periodStart).format('DD/MM/YYYY') : null"
        >
        </v-text-field>
      </v-col>
      <v-col cols="auto">
        <span class="subtitle-1">{{ $_strings.contract.label.UNTIL}} <span class="red--text">*</span></span>
      </v-col>
      <v-col md="3">
        <v-text-field
          height="30"
          outlined
          dense
          placeholder="Tanggal"
          :value="form.periodEnd ? dayjs(form.periodEnd).format('DD/MM/YYYY') : null"
          disabled
        >
        </v-text-field>
      </v-col>
    </v-row>

    <!-- ADENDUM -->
    <section v-if="contractBy === 'adendum-kontrak'">
      <v-row>
        <v-col cols="12" sm="4" md="3">
          <span class="subtitle-1">{{ $_strings.addendumContract.label.ADENDUM_SIGNED }} <span class="red--text">*</span></span>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            dense
            outlined
            item-value="name"
            item-text="name"
            placeholder="Tempat"
            v-model="form.locationName"
            @input.native="($event) => fetchCityList($event.target.value)"
            :items="cityList"
            :rules="rules.cityRule"
            :loading="isLoadingCityList"
            :clearable="!readonly"
            :disabled="readonly"
          >
          </v-autocomplete>
        </v-col>
        <v-col
          cols="auto"
        >
          <v-menu
            ref="signedDateShow"
            v-model="signedDateShow"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                prepend-inner-icon="mdi-calendar-month"
                label="Tanggal"
                single-line
                outlined
                dense
                aria-hidden="true"
                readonly
                persistent-hint
                v-bind="attrs"
                v-on="on"
                :value="form.addendumDate ? dayjs(form.addendumDate).format('DD/MM/YYYY') : null"
                :rules="rules.dateRule"
                :disabled="readonly"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.addendumDate"
              no-title
              locale="id"
              :disabled="readonly"
              @input="signedDateShow = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" md="3">
          <span class="subtitle-1">{{ $_strings.addendumContract.label.ADDENDUM_EFFECTIVE }} <span class="red--text">*</span></span>
        </v-col>
        <v-col cols="auto">
          <v-menu
            ref="effectiveDateShow"
            v-model="effectiveDateShow"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                prepend-inner-icon="mdi-calendar-month"
                label="Tanggal"
                single-line
                outlined
                dense
                aria-hidden="true"
                readonly
                persistent-hint
                v-bind="attrs"
                v-on="on"
                :value="form.effectiveDate ? dayjs(form.effectiveDate).format('DD/MM/YYYY') : null"
                :rules="rules.dateRule"
                :disabled="readonly"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.effectiveDate"
              no-title
              locale="id"
              :disabled="readonly"
              :min="form.periodStart ? dayjs(form.periodStart).format('YYYY-MM-DD') : null"
              :max="form.periodEnd ? dayjs(form.periodEnd).format('YYYY-MM-DD') : null"
              @input="effectiveDateShow = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </section>
  </v-form>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    itemsCompanyList: {
      type: Array,
      default: () => [],
    },
    cityList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    'form.companyType': function resetForm() {
      if (!(this.action === 'view')) {
        this.form.formatType = 'FASTLOG';
        this.form.companyId = '';
        this.form.effectiveDate = null;
        this.setForm();
      }
    },
    'form.companyId': function resetEffectiveDate() {
      if (this.contractBy === 'adendum-kontrak' && !(this.action === 'view')) {
        this.form.effectiveDate = null;
      }
    },
  },
  mounted() {
    if (!(this.action === 'view') && !(this.contractBy === 'adendum-kontrak')) {
      this.fetchCompanyList();
    }
    // GET LIST LOCATION ADENDUM
    if (this.contractBy === 'adendum-kontrak' && !(this.action === 'view')) {
      this.fetchCityList();
    }
  },
  data() {
    return {
      showTooltipCompanyName: false,
      rules: {
        contractFormatType: [(v) => !!v || 'Kontrak format wajib diisi'],
        companyNameRules: [(v) => !!v || 'Nama perusahaan wajib diisi'],
        partnerRule: [(v) => !!v || 'Mitra Kerja wajib diisi'],
        cityRule: [(v) => !!v || 'Tempat wajib diisi'],
        dateRule: [(v) => !!v || 'Tanggal wajib diisi'],
      },
      // cityList: [],
      // itemsCompanyList: [],
      radioGroupCompany: null,
      isLoadingCompanyList: false,
      isLoadingCityList: false,

      // DATE
      signedDateShow: false,
      effectiveDateShow: false,
    };
  },
  computed: {
    contractBy() {
      // adendum-kontrak | pengakhiran-kontrak | kontrak-shipper | kontrak-3pl
      const [, , path] = this.$route.path.split('/');
      return path;
    },
    contractFormatType() {
      if (this.contractBy === 'kontrak-shipper') {
        return 'Format Shipper';
      }
      return 'Format 3PL';
    },
    action() {
      const [, , , path] = this.$route.path.split('/');
      return path;
    },
    readonly() {
      return this.action === 'view';
    },
  },
  methods: {
    dayjs,
    serviceGetCompany() {
      switch (this.action) {
        case 'create': {
          if (this.contractBy === 'adendum-kontrak') {
            return this.$_services.contract.fetchCompanyListExistsContract;
          }
          return this.$_services.contract.fetchCompanyListContract;
        }
        default: return null;
      }
    },
    getType() {
      if (this.contractBy === 'kontrak-shipper' || this.form.companyType === 'SHIPPER') {
        return 'shipper';
      }
      if (this.contractBy === 'kontrak-3pl' || this.form.companyType === 'TRANSPORTER') {
        return 'transporter';
      }
    },
    async fetchCompanyList(companyName = '') {
      try {
        this.isLoadingCompanyList = true;
        const type = this.getType();
        const result = await this.serviceGetCompany()(companyName, type);
        this.$emit('updateCompanyList', result.data.contents);
      } finally {
        this.isLoadingCompanyList = false;
      }
    },
    async fetchCityList(name = '') {
      const parameterLike = `${name}`;
      try {
        this.isLoadingCityList = true;
        const cities = await this.$_services.locations.cities(parameterLike, 0);
        this.$emit('updateCityList', cities.data.contents);
      } finally {
        this.isLoadingCityList = false;
      }
    },
    setForm(companyId) {
      const { contractId } = this.$route.params;
      if (!companyId) {
        this.form.mouNo = '';
        this.form.contractNo = '';
        this.form.periodStart = '';
        this.form.periodEnd = '';
        this.form.mouId = '';
        if (contractId) {
          this.$router.push('/main/adendum-kontrak/create');
        }
        return;
      }
      const filterListCompany = this.itemsCompanyList.find((comp) => comp.companyId === companyId);
      this.form.mouNo = filterListCompany.mouNo;
      this.form.contractNo = this.generateContractNumber(filterListCompany.mouNo);
      this.form.periodStart = filterListCompany.mouPeriodStart;
      this.form.periodEnd = filterListCompany.mouPeriodEnd;
      this.form.mouId = filterListCompany.mouId;
      if (this.contractBy === 'adendum-kontrak') {
        // SET PARAMS CONTRACT ID
        this.$router.push(`/main/adendum-kontrak/create/${filterListCompany.contractId}`);
      }
    },
    generateContractNumber(val) {
      const mouArray = val.split('/');
      mouArray[1] = 'LGL';
      const result = `DRAFT-${mouArray.join('/')}`;
      return result;
    },
  },
};
</script>
